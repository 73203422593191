
import * as ipxRuntime$VjUzDENllf from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "cms-es.kingsleague.pro",
    "cms-am.kingsleague.pro",
    "s3.eu-central-2.wasabisys.com",
    "kingsleague-cdn.kama.football"
  ],
  "alias": {
    "/cms-es": "https://cms-es.kingsleague.pro/wp-content/uploads/",
    "/drafts-es": "https://cms-es.kingsleague.pro/old_drafts",
    "/cms-am": "https://cms-am.kingsleague.pro/wp-content/uploads/",
    "/s3": "https://s3.eu-central-2.wasabisys.com/kingsleague-cdn.kama.football/account/production",
    "/kama": "https://kingsleague-cdn.kama.football/account/"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$VjUzDENllf, defaults: {} }
}
        